.event-viewer {
   width: 100%;
}

.characterName {
  font-weight: 600;
}

/* .Ashy {
  background: rgba(68, 215, 168, 1)
}

.Ross {
  background: rgba(244, 194, 194, .2)
}

.Bryan {
  background: rgba(220, 20, 60, .2)
}

.zoomi {
  background: rgba(112, 128, 144, .2)
}

.Anita {
  background: rgba(201, 160, 220, .2)
}

.Soaring {
  background: rgba(244, 194, 194, .2)
}

.Tyler {
  background: rgba(195, 176, 145, .2)
} */


.events {
   display: flex;
   padding: 5px;
   /* margin-bottom: 10px; */
 }
 
 .dialogue {
   align-items: left;
 }
 
 .diceRoll {
   align-items: center;
 }
 